import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/Je0s1e10iEc"
    notesSrc="https://www.bible.com/events/7137772"
    notesText="Sermon Notes"
    bibleGroupSrc="https://www.docdroid.net/BXziM16/bible-group-homework-3-22.pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="When People Pray - Prayer During a Pandemic" />
  </Layout>
)

export default SermonPost
